import React from "react"
import Layout from "@/components/layout"
import ContentPage from "@/components/TriplicePageContent"
import SearchContextProvider from "@/context/search-context"
import MenuContextProvider from "@/context/menu-context"

const TripliceFronteira = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Triplice Fronteira">
          <ContentPage/>
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default TripliceFronteira;
